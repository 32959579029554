import { Global } from '@emotion/react'
import { lightTheme, ThemeProvider } from '@interest-protocol/ui-kit'
import { FC, PropsWithChildren } from 'react'
import { Toaster } from 'react-hot-toast'
import { SkeletonTheme } from 'react-loading-skeleton'
import '../../index.css'

import { GlobalStyles } from '@/ui/styles/GlobalStyles'

export function ThemeManager({ children }: PropsWithChildren<{}>) {
    return (
        <ThemeProvider theme={lightTheme}>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    duration: 10000,
                    style: {
                        border: '1px solid',
                        borderRadius: lightTheme.radii.m,
                        color: lightTheme.colors.onSurface,
                        background: lightTheme.colors.surface,
                        borderColor: lightTheme.colors.onPrimaryContainer,
                    },
                }}
            />

            <SkeletonTheme baseColor="#99BBFF28" highlightColor="#99BBFF14">
                {/* @ts-ignore */}
                <Global styles={GlobalStyles} />
                {children}
            </SkeletonTheme>
        </ThemeProvider>
    )
}
