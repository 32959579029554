import 'aos/dist/aos.css'
import './styles/home.module.scss'
import CircleImage from './public/circle.jpg'
import AOS from 'aos'

import style from './styles/home.module.scss'
import CruxLogo from '../deprecated/CruxLogo'
import { useEffect, useState } from 'react'
import { createClient } from '@supabase/supabase-js'
import { useFormik } from 'formik'
import { Button, Field, Input, Label } from '@headlessui/react'
import clsx from 'clsx'
import { Check } from './icons'

const supabase = createClient(
    'https://ysibktjlznsjproaffxq.supabase.co',
    import.meta.env.VITE_ENV_SUPABASE_ANON_KEY!
)
const validate = (values: any) => {
    const errors = {} as any

    if (!values.email) {
        errors.email = 'Required'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address'
    }

    return errors
}
enum SubmissionState {
    IDLE,
    SUBMITTING,
    SUCCESS,
    ERROR,
}
type PostError = {
    [key: string]: string
}
const CodeToErrorMessage: PostError = {
    '23505': 'Email already exists',
}
function Content() {
    const [submitted, setSubmitted] = useState(false)
    const [postError, setPostError] = useState('')
    const [submissionState, setSubmissionState] = useState(SubmissionState.IDLE)
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate,
        onSubmit: (values: any) => {
            setSubmissionState(SubmissionState.SUBMITTING)
            supabase
                .from('waitlist')
                .insert({ email: values.email })
                .then((res) => {
                    if (res.error) {
                        const codeExists = Object.keys(
                            CodeToErrorMessage
                        ).includes(res.error.code)
                        if (codeExists) {
                            const x = CodeToErrorMessage[`${res.error.code}`]

                            setPostError(x!)
                        } else {
                            setPostError('Unknown error')
                        }
                        setSubmissionState(SubmissionState.ERROR)
                    } else {
                        setSubmissionState(SubmissionState.SUCCESS)
                    }
                    return { data: res.data, error: res.error }
                })
        },
    })

    useEffect(() => {
        if (submissionState === SubmissionState.SUCCESS) {
            setTimeout(() => {
                setSubmissionState(SubmissionState.IDLE)
                setPostError('')
            }, 3000)
        }
    }, [submissionState])

    return (
        <div className="flex flex-col items-center gap-4 text-center justify-center align-middle">
            <CruxLogo
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-anchor="body"
                data-aos-duration={1000}
                className="svg-transition w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 mb-10 items-center flex justify-center drop-shadow-xl"
                // className="transition-transform duration-500 ease-in-out"
            />
            <div
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-easing="ease-in-out"
                data-aos-duration={1000}
                data-aos-anchor="body"
                // style={{ fontWeight: 700, fontFamily: 'avenir' }}
                className="font-avenir font-bold text-white text-3xl md:text-4xl  lg:text-5xl xl:text-6xl text-transition items-center drop-shadow-xl"
            >
                Crux
            </div>
            <div
                className="text-white drop-shadow-xl text-opacity-75 text-lg md:text-2xl lg:text-3xl xl:text-4xl text-transition font-avenir"
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-anchor="body"
                data-aos-easing="ease-in-out"
                data-aos-duration={1000}
            >
                Bitcoin's transaction layer.
            </div>
            <div
                className=" font-avenir text-white text-opacity-75 text-md lg:text-2xl xl:text-3xl pb-2 text-transition drop-shadow-xl"
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-anchor="body"
                data-aos-easing="ease-in-out"
                data-aos-duration={1000}
            >
                Coming soon...
            </div>
            <div
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-anchor="body"
                data-aos-easing="ease-in-out"
                data-aos-duration={1000}
            >
                <Field className="flex flex-col items-center justify-center align-middle">
                    <Label className="text-sm/6 font-medium text-white">
                        Submit your email to join the waitlist
                    </Label>
                    <Input
                        className={clsx(
                            'mt-3 block w-full text-white rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6',
                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                        )}
                        name="email"
                        id="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    {submissionState === SubmissionState.SUCCESS ? (
                        <Check className="w-8 h-8 flex items-center justify-center flex-col" />
                    ) : postError ? (
                        <span className="text-xs mt-2">{postError}</span>
                    ) : (
                        <span className="text-xs mt-2">
                            {/* @ts-ignore */}
                            {formik?.errors?.email}
                        </span>
                    )}
                    <Button
                        disabled={
                            formik?.errors?.email === undefined ? false : true
                        }
                        // @ts-ignore
                        onClick={formik.handleSubmit}
                        className={clsx(
                            `rounded bg-white bg-opacity-10 pt-2 mt-2 py-2 px-4 text-sm text-white data-[hover]:bg-sky-500 data-[active]:bg-sky-700 data-[disabled]:bg-gray-500 data-[disabled]:opacity-40`,
                            `${submissionState === SubmissionState.SUBMITTING ? 'opacity-80' : ''}`
                        )}
                    >
                        {submissionState === SubmissionState.SUBMITTING
                            ? 'Submitting...'
                            : 'Submit'}
                    </Button>
                </Field>
            </div>
        </div>
    )
}

function Circle() {
    return (
        <div className={style.circle}>
            <div className={style.circle__inner}>
                <div className={style.circle__image}>
                    <img
                        src={CircleImage}
                        alt="Circle"
                        width={1920}
                        height={1920}
                        loading="lazy"
                        draggable={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default function Home() {
    useEffect(() => {
        AOS.init({
            duration: 2000,
            easing: 'ease',
            once: true,
            mirror: true,
        })
        AOS.refresh()
    }, [])
    return (
        <div className="">
            <Circle />
            <Content />
        </div>
    )
}
