export default function CruxLogo({
    className,
    ...props
}: {
    className?: string
}) {
    const cname = className ? className : 'w-12 h-12'
    return (
        <svg
            className={cname}
            width="4004"
            height="4008"
            viewBox="0 0 4004 4008"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <ellipse
                cx="1995.19"
                cy="2004.81"
                rx="1995.19"
                ry="1995.19"
                fill="#FF8C7D"
            />
            <g filter="url(#filter0_d_190_1345)">
                <mask
                    id="mask0_190_1345"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="4000"
                    height="4000"
                >
                    <circle cx="2000" cy="2000" r="2000" fill="#FF8C7D" />
                </mask>
                <g mask="url(#mask0_190_1345)">
                    <ellipse
                        cx="2004.81"
                        cy="4197.12"
                        rx="1995.19"
                        ry="1995.19"
                        fill="#FFDF5E"
                    />
                    <g filter="url(#filter1_b_190_1345)">
                        <rect
                            x="461.539"
                            y="2278.85"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <g filter="url(#filter2_b_190_1345)">
                        <rect
                            x="461.539"
                            y="3163.46"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <g filter="url(#filter3_b_190_1345)">
                        <rect
                            x="461.539"
                            y="3605.77"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <g filter="url(#filter4_b_190_1345)">
                        <rect
                            x="461.539"
                            y="2721.15"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <g filter="url(#filter5_b_190_1345)">
                        <rect
                            x="2894.23"
                            y="2278.85"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <g filter="url(#filter6_b_190_1345)">
                        <rect
                            x="2894.23"
                            y="3163.46"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <g filter="url(#filter7_b_190_1345)">
                        <rect
                            x="2894.23"
                            y="3605.77"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <g filter="url(#filter8_b_190_1345)">
                        <rect
                            x="2894.23"
                            y="2721.15"
                            width="634.615"
                            height="432.692"
                            rx="20"
                            fill="#FF5757"
                        />
                    </g>
                    <path
                        d="M3518.46 2269.23C3529.51 2269.23 3538.48 2260.28 3538.32 2249.23C3537.19 2169.98 3529.37 2090.94 3514.93 2012.88C3512.94 2002.15 3502.6 1995.14 3491.86 1997.09L2900.24 2105.01C2889.24 2107.02 2882.05 2117.64 2883.96 2128.65C2890.9 2168.53 2894.88 2208.82 2895.86 2249.23C2896.13 2260.28 2905.06 2269.23 2916.11 2269.23H3518.46Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M3488.91 1986.02C3499.9 1983.94 3507.05 1973.25 3504.61 1962.33C3487.32 1884.79 3463.49 1808.71 3433.37 1734.84C3429.3 1724.84 3417.93 1720.04 3407.86 1723.94L2849.8 1940.08C2839.27 1944.16 2834.23 1956.13 2838.37 1966.63C2853.32 2004.51 2865.45 2043.35 2874.65 2082.86C2877.13 2093.49 2887.48 2100.42 2898.2 2098.38L3488.91 1986.02Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M3402.16 1713.85C3412.66 1709.69 3417.65 1697.66 3413.01 1687.36C3380.2 1614.5 3341.28 1544.29 3296.62 1477.43C3290.67 1468.51 3278.69 1466.04 3269.6 1471.74L2766.62 1787.69C2756.99 1793.73 2754.35 1806.57 2760.56 1816.09C2783.01 1850.54 2802.87 1886.47 2819.99 1923.62C2824.51 1933.43 2835.87 1938.15 2845.91 1934.17L3402.16 1713.85Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M3261.62 1463.12C3271.2 1457 3273.77 1444.13 3267.12 1434.9C3220.04 1369.51 3167.49 1307.87 3110 1250.58C3102.42 1243.03 3090.23 1242.95 3082.44 1250.28L2653.83 1653.75C2645.53 1661.56 2645.44 1674.7 2653.43 1682.84C2682.58 1712.54 2709.48 1744.19 2733.92 1777.56C2740.26 1786.21 2752.22 1788.6 2761.26 1782.83L3261.62 1463.12Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M3072.74 1243.42C3080.98 1235.55 3080.99 1222.38 3072.64 1214.62C3013.07 1159.21 2948.9 1108.52 2880.76 1063.02C2871.85 1057.07 2859.84 1059.37 2853.63 1068.11L2515.76 1543.51C2509.18 1552.78 2511.65 1565.65 2521.04 1572.07C2555.82 1595.84 2588.79 1621.97 2619.68 1650.24C2627.57 1657.47 2639.73 1657.49 2647.47 1650.09L3072.74 1243.42Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M2842.78 1063.1C2849.3 1053.78 2846.75 1040.9 2837.08 1034.91C2767.3 991.634 2693.97 953.842 2617.8 921.905C2607.84 917.73 2596.42 922.363 2592.05 932.234L2357.78 1461.27C2353.2 1471.6 2358.1 1483.65 2368.47 1488.12C2407.59 1505 2445.38 1524.53 2481.57 1546.58C2490.73 1552.16 2502.7 1549.8 2508.84 1541.01L2842.78 1063.1Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M2580.54 929.004C2585.03 918.641 2580.06 906.607 2569.5 902.586C2492.19 873.126 2412.5 849.713 2331.23 832.578C2320.56 830.328 2310.13 837.186 2307.82 847.846L2186.03 1410.24C2183.66 1421.17 2190.72 1431.89 2201.65 1434.31C2243.57 1443.56 2284.74 1455.7 2324.84 1470.61C2334.96 1474.38 2346.33 1469.69 2350.63 1459.78L2580.54 929.004Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M509.026 1992.69C498.293 1990.7 487.932 1997.67 485.911 2008.4C471.215 2086.41 463.136 2165.43 461.753 2244.68C461.56 2255.73 470.504 2264.71 481.551 2264.74L1083.9 2266.52C1094.95 2266.56 1103.9 2257.63 1104.21 2246.59C1105.32 2206.18 1109.43 2165.9 1116.49 2126.04C1118.44 2115.04 1111.28 2104.39 1100.29 2102.35L509.026 1992.69Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M594.142 1719.24C584.083 1715.31 572.702 1720.06 568.588 1730.05C538.199 1803.82 514.097 1879.82 496.517 1957.31C494.043 1968.21 501.159 1978.93 512.137 1981.06L1102.43 2095.39C1113.14 2097.46 1123.51 2090.56 1126.03 2079.95C1135.38 2040.46 1147.65 2001.66 1162.74 1963.84C1166.92 1953.35 1161.91 1941.36 1151.4 1937.25L594.142 1719.24Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M733.317 1467.51C724.254 1461.77 712.267 1464.2 706.279 1473.1C661.381 1539.81 622.201 1609.89 589.121 1682.65C584.446 1692.93 589.394 1704.98 599.881 1709.17L1155.32 1931.35C1165.35 1935.36 1176.72 1930.68 1181.28 1920.89C1198.53 1883.8 1218.52 1847.93 1241.1 1813.55C1247.34 1804.05 1244.75 1791.21 1235.14 1785.13L733.317 1467.51Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M921.287 1246.67C913.523 1239.31 901.332 1239.36 893.727 1246.88C836.027 1303.98 783.255 1365.44 735.929 1430.68C729.252 1439.88 731.775 1452.77 741.336 1458.92L1240.52 1780.29C1249.54 1786.1 1261.51 1783.74 1267.88 1775.11C1292.44 1741.83 1319.46 1710.27 1348.72 1680.66C1356.73 1672.55 1356.69 1659.41 1348.42 1651.57L921.287 1246.67Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M1150.76 1065.27C1144.58 1056.51 1132.58 1054.16 1123.64 1060.09C1055.34 1105.36 990.987 1155.84 931.213 1211.05C922.841 1218.78 922.804 1231.95 931.013 1239.85L1354.8 1647.93C1362.5 1655.35 1374.67 1655.38 1382.58 1648.18C1413.58 1620.01 1446.64 1593.99 1481.52 1570.33C1490.93 1563.95 1493.44 1551.08 1486.89 1541.79L1150.76 1065.27Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M1412.84 930.272C1408.5 920.384 1397.1 915.71 1387.12 919.851C1310.84 951.535 1237.36 989.082 1167.42 1032.12C1157.74 1038.08 1155.15 1050.95 1161.62 1060.29L1493.82 1539.31C1499.93 1548.13 1511.89 1550.53 1521.07 1544.98C1557.34 1523.05 1595.21 1503.64 1634.39 1486.9C1644.78 1482.46 1649.71 1470.43 1645.17 1460.08L1412.84 930.272Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M1697.37 846.834C1695.1 836.163 1684.69 829.268 1674.01 831.482C1592.68 848.346 1512.9 871.493 1435.48 900.695C1424.92 904.68 1419.91 916.695 1424.36 927.072L1652.33 1458.6C1656.59 1468.53 1667.95 1473.26 1678.08 1469.53C1718.24 1454.75 1759.45 1442.75 1801.41 1433.64C1812.34 1431.26 1819.44 1420.56 1817.11 1409.62L1697.37 846.834Z"
                        fill="#FF5757"
                    />
                    <path
                        d="M2296.24 818.408C2298.39 807.574 2291.36 797.031 2280.5 795.018C2096.68 760.931 1908.18 760.635 1724.26 794.144C1713.39 796.124 1706.32 806.644 1708.44 817.484L1832.74 1452.79C1834.86 1463.63 1845.37 1470.68 1856.25 1468.81C1952.27 1452.36 2050.41 1452.51 2146.38 1469.27C2157.26 1471.17 2167.79 1464.16 2169.94 1453.32L2296.24 818.408Z"
                        fill="#FFDF5E"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_190_1345"
                    x="5.61572"
                    y="769.231"
                    width="3998.38"
                    height="3238.77"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_b_190_1345"
                    x="457.539"
                    y="2274.85"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_b_190_1345"
                    x="457.539"
                    y="3159.46"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_b_190_1345"
                    x="457.539"
                    y="3601.77"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_b_190_1345"
                    x="457.539"
                    y="2717.15"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter5_b_190_1345"
                    x="2890.23"
                    y="2274.85"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter6_b_190_1345"
                    x="2890.23"
                    y="3159.46"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter7_b_190_1345"
                    x="2890.23"
                    y="3601.77"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter8_b_190_1345"
                    x="2890.23"
                    y="2717.15"
                    width="642.615"
                    height="440.692"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_190_1345"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_190_1345"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}
