import { createNetworkConfig } from '@mysten/dapp-kit'
import { getFullnodeUrl } from '@mysten/sui.js/client'

import { DEVNET_VAULT_PACKAGE_ID, MAINNET_VAULT_PACKAGE_ID } from './constants'

const { networkConfig, useNetworkVariable, useNetworkVariables } =
    createNetworkConfig({
        devnet: {
            url: getFullnodeUrl('devnet'),
            variables: {
                packageId: DEVNET_VAULT_PACKAGE_ID,
            },
        },
        mainnet: {
            url: getFullnodeUrl('mainnet'),
            variables: {
                packageId: MAINNET_VAULT_PACKAGE_ID,
            },
        },
    })

export { networkConfig, useNetworkVariable, useNetworkVariables }
