import '@mysten/dapp-kit/dist/index.css'
import './index.css'
import './fonts/style.css'

import { QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { queryClient } from './utils/queryClient'

import { SuiClientProvider } from '@mysten/dapp-kit'
import { ThemeManager } from './ui/deprecated/ThemeManager'

import { rootRouter } from './RootRouter'
import { TooltipProvider } from './ui/atoms/tooltip/Tooltip'
import { NextUIProvider } from '@nextui-org/react'
import { WalletProvider } from '@suiet/wallet-kit'
import { networkConfig } from './networkConfig'

function App() {
    return (
        <NextUIProvider>
            <ThemeManager>
                <SuiClientProvider
                    networks={networkConfig}
                    defaultNetwork="devnet"
                >
                    <QueryClientProvider client={queryClient}>
                        <WalletProvider autoConnect>
                            <Toaster
                                position="top-right"
                                containerClassName="toast-notifications"
                            />
                            <TooltipProvider delayDuration={0}>
                                <RouterProvider router={rootRouter} />
                            </TooltipProvider>
                        </WalletProvider>
                    </QueryClientProvider>
                </SuiClientProvider>
            </ThemeManager>
        </NextUIProvider>
    )
}

export default App
