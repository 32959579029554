import { css } from '@emotion/react'

export const GlobalStyles = css`

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
';
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        background: white;
    }

    body,
    html {
        overflow-x: hidden;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
        padding: 2rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 0.5rem;
        background: transparent;
        transition: all 300ms ease-in-out;
    }

    /* Track on hover */
    ::-webkit-scrollbar-track:hover {
        background: #fff1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #777777;
        border-radius: 0.5rem;
        border: 5px solid transparent;
    }
`
