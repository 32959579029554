import { useRouteError } from 'react-router-dom'

import { NotConnected } from './NotConnected'
import { NotFound } from './NotFound'
import { UnknownError } from './UnknownError'

interface RouterErrorFallbackProps {
    fullScreen?: boolean
}

export function RouterErrorFallback({ fullScreen }: RouterErrorFallbackProps) {
    const error = useRouteError()

    return <UnknownError error={error} fullScreen={fullScreen} />
}
