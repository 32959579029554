import { createBrowserRouter } from 'react-router-dom'

import { RouterErrorFallback } from './features/errors'
import { NotFound } from './features/errors/NotFound'
import { RootRoute } from './pages/Root'

export const rootRouter = createBrowserRouter([
    {
        path: '/',
        element: <RootRoute />,
        errorElement: <RouterErrorFallback fullScreen />,
        children: [
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
])
