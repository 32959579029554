import { cx } from 'class-variance-authority'
import { useState } from 'react'

import { NavbarLayout } from '@/features/navbar/NavBarLayout'
import { cn } from '@/ui/utils/style'
import Home from '../landing/page'

interface AppLayoutProps {
    children: React.ReactNode
}

export function AppLayout({ children }: AppLayoutProps) {
    const [mobileMenuCollapsed, setMobileMenuCollapsed] = useState(true)

    return (
        <div className="flex flex-col h-screen w-full items-center justify-center place-items-center">
            {/* <NavbarLayout /> */}
            {/* <div
                className={cn(
                    'flex h-full w-full flex-col ',
                    mobileMenuCollapsed && 'pb-5'
                )}
            > */}
            <Home />
            {/* <div
                    className={cx(
                        'flex w-full grow flex-col z-20',
                        !mobileMenuCollapsed && 'hidden lg:flex'
                    )}
                >
                    {children}
                </div> */}
            {/* </div> */}
        </div>
    )
}
