import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { ErrorBoundary, RouterErrorFallback } from '@/features/errors'
import { AppLayout } from '@/ui/layouts/AppLayout'
import { FallbackLayout } from '@/ui/layouts/FallbackLayout'

export function RootRoute() {
    return (
        <Suspense fallback={<FallbackLayout />}>
            <AppLayout>
                <ErrorBoundary fallback={<RouterErrorFallback />}>
                    <Outlet />
                </ErrorBoundary>
            </AppLayout>
        </Suspense>
    )
}
